<template>
  <Button
    :theme
    :size
    :disabled
    :text
    :suffix-icon
    class="[&>svg]:transition-transform [&>svg]:duration-150 [&>svg]:ease-in-out"
    :class="{
      '[&>svg]:-rotate-180': open,
    }"
    @click="open ? emit('close') : emit('open')"
  />
</template>

<script setup lang="ts">
defineOptions({
  name: 'DropdownButton',
})

interface Props {
  text: string
  theme?: ButtonThemes
  size?: ButtonSizes
  open: boolean
  disabled?: boolean
  direction?: DropdownDirection
}

const props = withDefaults(defineProps<Props>(), {
  text: undefined,
  theme: BUTTON_THEME.SECONDARY,
  size: BUTTON_SIZE.SM,
  direction: DROPDOWN_DIRECTION.DOWN,
  disabled: false,
})

const emit = defineEmits(['open', 'close'])

const suffixIcon = computed(() =>
  props.direction === DROPDOWN_DIRECTION.UP
    ? 'kh:chevron-up'
    : 'kh:chevron-down'
)
</script>
